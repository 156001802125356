import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Rewards from './Rewards/Rewards';
import Samples from './Samples/Samples';
import TopRewards from './TopRewards/TopRewards';
import "bootstrap/dist/css/bootstrap.min.css";
import UserForm from './components/UserForm/UserForm';
import OneSignal from 'react-onesignal';
import { useEffect } from 'react';
import TermsandConditions from './Terms&Conditions/Terms&Conditions';
import PrivacyPolicy from './Terms&Conditions/PrivacyPolicy';
import TermsandConditionsCA from './Terms&Conditions CA/Terms&ConditionsCA';
import PrivacyPolicyCA from './Terms&Conditions CA/PrivacyPolicyCA';
import UserFormCA from './components/UserFormCA/UserFormCA';

function App() {
  // useEffect(() => {
  //    OneSignal.init({
  //      appId: "8c49a8b2-6824-48bd-b32e-f9db7ef8820a"
  //    });
  //  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
        <Route exact path="/rewards/cash100" render={() => <Rewards pageName={'Cash 100'} />} />
        <Route exact path="/rewards/cash750" render={() => <Rewards pageName={'Cash 750'} />} />
        <Route exact path="/rewards/amazon100" render={() => <Rewards pageName={'Amazon 100'} />} />
        <Route exact path="/samples/kfc" render={() => <Samples pageName={'kfc samples'} />} />
        <Route exact path="/dataflowpage" render={() => <UserForm />} />
        <Route exact path="/rewards/burgerking100" render={() => <Rewards pageName={'Burger King 100'} />} />
        <Route exact path="/samples/wendys" render={() => <Samples pageName={'wendys samples'} />} />
        <Route exact path="/samples/tacobell" render={() => <Samples pageName={'tacobell samples'} />} />
        <Route exact path="/samples/mcdonalds" render={() => <Samples pageName={'mcdonalds samples'} />} />
        <Route exact path="/samples/burgerking" render={() => <Samples pageName={'burgerking samples'} />} />
        <Route exact path="/samples/kitkat" render={() => <Samples pageName={'kitkat samples'} />} />
        <Route exact path="/rewards/pizzahut1000" render={() => <Rewards pageName={'Pizza Hut 1000'} />} />
        <Route exact path="/samples/pizzahut" render={() => <Samples pageName={'pizzahut samples'} />} />
        <Route exact path="/samples/dunkindonuts" render={() => <Samples pageName={'dunkindonuts samples'} />} />
        <Route exact path="/rewards/shein750" render={() => <Rewards pageName={'Shein 750'} />} />
        <Route exact path="/rewards/amazon100" render={() => <Rewards pageName={'Amazon 100'} />} />
        <Route exact path="/samples/cocacolafridge" render={() => <Samples pageName={'cocacolafridge samples'} />} />
        <Route exact path="/samples/dunkindonuts" render={() => <Samples pageName={'dunkindonuts samples'} />} />


        <Route exact path="/termsandconditions" render={() => <TermsandConditions />} />
          <Route exact path="/privacypolicy" render={() => <PrivacyPolicy />} />
          
          {/* <Route path="/rewards/walmart100" render={() => <Rewards pageName={'Walmart 100'} />} />
          <Route path="/rewards/walmart250" render={() => <Rewards pageName={'Walmart 250'} />} /> */}
          {/* <Redirect path="/" exact to="/rewards/cash100" /> */}


          {/* <Route exact path="/rewards/cash100" render={() => <Rewards pageName={'Cash 100'} />} />
          <Route exact path="/rewards/cash250" render={() => <Rewards pageName={'Cash 250'} />} />
          <Route exact path="/rewards/cash500" render={() => <Rewards pageName={'Cash 500'} />} />
          <Route exact path="/rewards/cash750" render={() => <Rewards pageName={'Cash 750'} />} />
          <Route exact path="/rewards/cash1000" render={() => <Rewards pageName={'Cash 10-0'} />} />
          <Route exact path="/rewards/walmart100" render={() => <Rewards pageName={'Walmart 100'} />} />
          <Route exact path="/rewards/walmart500" render={() => <Rewards pageName={'Walmart 500'} />} />
          <Route exact path="/rewards/walmart750" render={() => <Rewards pageName={'Walmart 750'} />} />
          <Route exact path="/rewards/amazon100" render={() => <Rewards pageName={'Amazon 100'} />} />
          <Route exact path="/rewards/chipotle100" render={() => <Rewards pageName={'Chipotle 100'} />} />
          <Route exact path="/rewards/kroger500" render={() => <Rewards pageName={'Kroger 500'} />} />
          <Route exact path="/rewards/burgerking150" render={() => <Rewards pageName={'Burger King 150'} />} />
          <Route exact path="/rewards/sephora750" render={() => <Rewards pageName={'Sephora 750'} />} />
          <Route exact path="/rewards/pizzahut1000" render={() => <Rewards pageName={'Pizza Hut 1000'} />} />
          <Route exact path="/rewards/shein750" render={() => <Rewards pageName={'Shein 750'} />} />
          <Route exact path="/rewards/fnplaystation5" render={() => <Rewards pageName={'fnps5 rewards'} />} />
          <Route exact path="/rewards/fnplaystation5-1" render={() => <Rewards pageName={'fn2ps5 rewards'} />} />
          <Route exact path="/rewards/robloxsamsungs221" render={() => <Rewards pageName={'roblox samsungs221 rewards'} />} />
          <Route exact path="/rewards/iphone14" render={() => <Rewards pageName={'iphone14 rewards'} />} />
          <Route exact path="/rewards/iphone15" render={() => <Rewards pageName={'iphone15 rewards'} />} />
          <Route exact path="/rewards/venmo750" render={() => <Rewards pageName={'venmo 750'} />} />
          <Route exact path="/rewards/ups750" render={() => <Rewards pageName={'ups 750'} />} />
          <Route exact path="/rewards/fedex100" render={() => <Rewards pageName={'fedex 100'} />} />
          <Route exact path="/rewards/amazonprime1" render={() => <Rewards pageName={'amazon prime'} />} />
          <Route exact path="/rewards/temu750" render={() => <Rewards pageName={'temu 750'} />} />
          <Route exact path="/rewards/ps5fc24" render={() => <Rewards pageName={'ps5 fc24'} />} />

          <Route exact path="/rewards/amazon100halloween" render={() => <Rewards pageName={'amazon 100 halloween'} />} />
          <Route exact path="/rewards/visa1000halloween" render={() => <Rewards pageName={'visa 1000 halloween'} />} />
          <Route exact path="/rewards/fashionnova750halloween" render={() => <Rewards pageName={'fashionnova 750 halloween'} />} />
          <Route exact path="/rewards/target100halloween" render={() => <Rewards pageName={'target 100 halloween'} />} />
          <Route exact path="/rewards/shein750halloween" render={() => <Rewards pageName={'shein 750 halloween'} />} />

          <Route exact path="/rewards/paypal750" render={() => <Rewards pageName={'paypal 750'} />} />
          <Route exact path="/rewards/capitaloneplatinum1000" render={() => <Rewards pageName={'capitaloneplatinum 1000'} />} />
          <Route exact path="/rewards/mastercardplatinum1000" render={() => <Rewards pageName={'mastercardplatinum 1000'} />} />
          <Route exact path="/rewards/earning1000" render={() => <Rewards pageName={'earning 1000'} />} />
          <Route exact path="/rewards/amazon750productreview" render={() => <Rewards pageName={'amazon 750 productreview'} />} />

          <Route
            exact
            path="/samples/chickfila"
            render={() => <Samples pageName={'chickfila samples'} />}
          />
          <Route exact path="/samples/ihop" render={() => <Samples pageName={'ihop samples'} />} />
          <Route
            exact
            path="/samples/quiznos"
            render={() => <Samples pageName={'quiznos samples'} />}
          />
          <Route exact path="/samples/m&ms" render={() => <Samples pageName={'m&ms samples'} />} />
          <Route exact path="/samples/lays" render={() => <Samples pageName={'lays samples'} />} />
          <Route exact path="/samples/kfc" render={() => <Samples pageName={'kfc samples'} />} />
          <Route exact path="/samples/mcdonalds" render={() => <Samples pageName={'mcdonalds samples'} />} />
          <Route exact path="/samples/pizzahut" render={() => <Samples pageName={'pizzahut samples'} />} />
          <Route exact path="/samples/burgerking" render={() => <Samples pageName={'burgerking samples'} />} />
          <Route exact path="/samples/wendys" render={() => <Samples pageName={'wendys samples'} />} />
          <Route exact path="/samples/fnplaystation5" render={() => <Samples pageName={'fnps5 samples'} />} />
          <Route exact path="/samples/fnplaystation5-1" render={() => <Samples pageName={'fn2ps5 samples'} />} />
          <Route exact path="/samples/robloxsamsungs22-1" render={() => <Samples pageName={'roblox samsungs22 samples'} />} />
          <Route exact path="/samples/kitkat" render={() => <Samples pageName={'kitkat samples'} />} />
          <Route exact path="/samples/subway" render={() => <Samples pageName={'subway samples'} />} />
          <Route exact path="/samples/cocacolafridge" render={() => <Samples pageName={'cocacolafridge samples'} />} />
          <Route exact path="/samples/tacobell" render={() => <Samples pageName={'tacobell samples'} />} />
          <Route exact path="/samples/dunkindonuts" render={() => <Samples pageName={'dunkindonuts samples'} />} />
          <Route exact path="/samples/reeses" render={() => <Samples pageName={'reeses samples'} />} />
          <Route exact path="/samples/starbucks" render={() => <Samples pageName={'starbucks samples'} />} />
          <Route exact path="/samples/bathbodyworks" render={() => <Samples pageName={'bathbodyworks samples'} />} />
          <Route exact path="/samples/victoriassecret" render={() => <Samples pageName={'victoriassecret samples'} />} />
          <Route exact path="/samples/maccosmetics" render={() => <Samples pageName={'maccosmetics samples'} />} />


          <Route exact path="/toprewards/cash750" render={() => <TopRewards pageName={'Cash 750'} />} />

          <Route exact path="/dataflowpage" render={() => <UserForm />} />
          <Route exact path="/dataflowpageca" render={() => <UserFormCA />} />
          <Route exact path="/termsandconditions" render={() => <TermsandConditions />} />
          <Route exact path="/privacypolicy" render={() => <PrivacyPolicy />} />
          <Route exact path="/termsandconditionsca" render={() => <TermsandConditionsCA />} />
          <Route exact path="/privacypolicyca" render={() => <PrivacyPolicyCA />} />

          <Route exact path="/samples/m&ms" render={() => <Samples pageName={'M&M'} />} /> */}

        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
